<template>
	<div class="modalBox" :style="{ 'z-index': zIndex }">
		<div class="content" :class="[ out ? 'content_out': 'content_in']">
			<slot name="content"></slot>
			<div class="close_icon" v-if="showClose" @click="tapMask">
				<img src="https://tgimage.xiangbaoche.cn/wap_btn_close.png" >
			</div>
		</div>
		<div class="mask" @click="tapMask"></div>
	</div>
</template>

<script>
	export default{
		props: {
			zIndex: {
				type: Number,
				default: 100
			},
			showClose: {
				type: Boolean,
				default: true
			}
		},
		data() {
			return {
				out: false
			}
		},
		methods: {
			tapMask() {
				this.out = true
				setTimeout(() => {
					this.$emit('close')
				}, 300)
			}
		}
	}
</script>

<style scoped>
	.modalBox{
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: 1000;
	}
  .content{
  	position: absolute;
  	left: 50%;
  	top: 50%;
  	transform: translate(-50%, -50%);
  	z-index: 2;
  }
  .content_in{
  	animation: scaleIn 0.3s linear;
  }
  .content_out{
  	animation: scaleOut 0.4s linear;
  }
  .mask{
  	position: absolute;
  	left: 0;
  	top: 0;
  	width: 100%;
  	height: 100%;
  	z-index: 1;
  	background-color: rgba(0,0,0,0.5);
  }
  .close_icon{
  	margin-top: 34px;
  	text-align: center;
  }
  .close_icon img{
  	width: 30px;
  	height: 30px;
    cursor: pointer;
  }
	@keyframes scaleIn {
		0% {
			transform: translate(-50%, -50%) scale(0);
		}

		100% {
			transform: translate(-50%, -50%) scale(1);
		}
	}

	@keyframes scaleOut {
		0% {
			transform: translate(-50%, -50%) scale(1);
		}

		100% {
			transform: translate(-50%, -50%) scale(0);
		}
	}

</style>
