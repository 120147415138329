<template>
  <div>
    <div class="pager_footer">
      <div class="bg_img">
        <img src="https://tgimage.xiangbaoche.cn/footer_img001.png" alt="">
      </div>
      <div class="flex_line">
        <div>应用名：享包车出行</div>
        <div class="line"></div>
        <div>更新时间：{{ versionData.android_update_time }}</div>
      </div>
      <div class="flex_line">
       <div>开发者：{{ versionData.developer }}</div>
       <div class="line"></div>
       <div>版本号：{{ versionData.android }}</div>
      </div>
      <div class="flex_line">
        <div class="version_btn" @click="showAuth=true">应用权限</div>
        <div class="version_btn" @click="showAgree=true">隐私协议</div>
      </div>
    </div>

    <Agreement v-if="showAgree" @close="showAgree=false"/>
    <AuthBox v-if="showAuth" @close="showAuth=false" :auth_list="auth_list"/>
  </div>

</template>

<script>
  import { getConfig } from '@/utils/api.js'
  import Agreement from '@/components/agreement/index.vue'
  import AuthBox from '@/components/authBox/index.vue'
  export default {
    data() {
      return {
        versionData: {},
        auth_list: [],
        showAuth: false,
        showAgree: false
      }
    },
    components: {
      Agreement,
      AuthBox
    },
    created() {
      getConfig()
      .then(res => {
        if(res.data.errCode == 110) {
          this.versionData = res.data.resData
          this.auth_list = res.data.resData.auth
        }
      })
    }
  }
</script>

<style scoped>
  .pager_footer{
    padding-top: 10px;
  }
  .bg_img{
    text-align: center;
    height: 20px;
  }
  .bg_img img{
    height: 100%;
  }
  .flex_line{
    justify-content: center;
    color: #666;
    display: flex;
    margin-top: 10px;
    font-size: 14px;
  }
  .line{
    margin: 0 10px;
    height: 15px;
    width: 1px;
    background: #DADADA;
  }
  .version_btn{
    color: #0085D8;
    margin: 0 10px;
    cursor: pointer;
  }
</style>
