<template>
  <div class="auth_box">
    <Modal @close="close">
      <div class="my_content" slot="content">
        <div class="title">应用权限</div>
        <p class="my_para" v-for="(item, index) in auth_list">
         {{ index+1 }} 、{{ item }}
        </p>
      </div>
    </Modal>
  </div>
</template>

<script>
  import Modal from '@/components/modal/index.vue'
  export default {
    props: {
      auth_list: {
        type: Array,
        default: function() {
          return []
        }
      }
    },
    components: {
      Modal
    },
    methods: {
      close() {
        this.$emit('close')
      }
    }
  }
</script>

<style scoped>
  .my_content{
    width: 800px;
    border-radius: 10px;
    background: #ffffff;
    padding: 20px;
  }
  .title{
    color: #000000;
    font-weight: bold;
    font-size: 20px;
    text-align: center;
    margin-bottom: 10px;
  }
  .my_para{
    font-size: 14px;
    margin-bottom: 10px;
    color: #333;
  }
</style>
