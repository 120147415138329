<template>
  <div>
    <div class="version" :class="[ white ? 'white_version': 'black_version' ]">
      <div class="developer" v-if="showDev">
        开发者：{{ versionData.developer }}
      </div>
      <div class="btn_group">
        <div class="version_num">版本号：{{ versionData.android }}</div>
        <div class="line"></div>
        <div class="version_btn" @click="showAuth=true">应用权限</div>
        <div class="line"></div>
        <div class="version_btn" @click="showAgree=true">隐私协议</div>
      </div>
    </div>
    <AuthBox v-if="showAuth" @close="showAuth=false" :auth_list="auth_list"/>
    <Agreement v-if="showAgree" @close="showAgree=false"/>
  </div>
  
</template>

<script>
  import { getConfig } from '@/utils/api.js'
  import Agreement from '@/components/agreement/index.vue'
  import AuthBox from '@/components/authBox/index.vue'
  export default {
    props: {
      white: {
        type: Boolean,
        default: true
      },
      showDev: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        versionData: {},
        auth_list: [],
        showAuth: false,
        showAgree: false
      }
    },
    components: {
      Agreement,
      AuthBox
    },
    created() {
      getConfig()
      .then(res => {
        if(res.data.errCode == 110) {
          this.versionData = res.data.resData
          this.auth_list = res.data.resData.auth
        }
      })
    }
  }
</script>

<style scoped>
  .version{
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 12px;
    padding: 0 10px;
  }
  .white_version{
    color: #FFFFFF;
  }
  .black_version{
    color: #333;
  }
  .btn_group{
    display: flex;
    align-items: center;
  }
  .version_num{
    color: #C5C5C5;
  }
  .white_version .line{
    width: 1px;
    height: 11px;
    background: #7F7F7F;
    margin: 0 10px;
  }
  .black_version .line{
    background: #7F7F7F;
    width: 1px;
    height: 11px;
    margin: 0 10px;
  }
  .version_btn{
    color: #6EC0FF;
    cursor: pointer;
  }
</style>
