<template>
  <div class="homePage">
    <div class="nav">
      <div class="nav_inner">
        <div class="nav_left">
          <div class="logo_box">
            <img src="../assets/images/tg_logo.png" alt="">
          </div>
          <div class="logo_txt">
            <div class="txt_01">享包车出行</div>
            <div class="txt_02">全国4至57座包车平台</div>
          </div>
        </div>
        <div class="nav_right">
          <div class="company_introduction" @click="isAbout = true">公司简介</div>
          <div class="nav_red_pack">
            <img src="../assets/images/c_site_red_pack.png" alt="">
          </div>
          <div class="red_pack_text">
            <span class="red_pack_text_number">99</span>
            <span class="red_pack_text_unit">元</span>免费券+
            <span class="red_pack_text_number">20</span>
            <span class="red_pack_text_unit">万元</span>/人意外险
          </div>

          <version />

          <div class="nav_right_down_btn">
            <div @mouseenter="show_down = true" @mouseleave="show_down = false">
              下载领取
            </div>
            <div class="down_load_code" v-show="show_down">
              <img src="../assets/images/down_load_code.png" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="main">
      <div class="banner_item" style="cursor: pointer;" @click="playVideo">
        <img class="banner_img" src="https://tgimage.xiangbaoche.cn/d_site_mobile01.png?t=1" alt="">
      </div>
      <div class="banner_item">
        <img class="banner_img" src="https://tgimage.xiangbaoche.cn/d_site_mobile02.png" alt="">
      </div>
      <div class="banner_item">
        <img class="banner_img" src="https://tgimage.xiangbaoche.cn/d_site_mobile03.png" alt="">
        <div class="count_to_number" id="count_to_number">
          <h1 class="count_to_number_title">APP上线已满5年用数据说话</h1>
          <div class="yellow_line"></div>
          <div class="num_wrapper">
            <div class="num_box">
              <div class="num_box_number">
                <countTo :startVal='0' :endVal='endVal.value1' :duration='3000'></countTo>
                <span class="num_box_unit">万+</span></div>
              <div class="num_box_text">服务旅客/人</div>
            </div>
            <div class="num_box">
              <div class="num_box_number">
                <countTo :startVal='0' :endVal='endVal.value2' :duration='3000'></countTo>
              </div>
              <div class="num_box_text">入驻车队/数</div>
            </div>
            <div class="num_box">
              <div class="num_box_number">
                <countTo :startVal='0' :endVal='endVal.value3' :duration='3000'></countTo>
              </div>
              <div class="num_box_text">车型品牌/款</div>
            </div>
          </div>
        </div>
      </div>
      <div class="banner_item">
        <img class="banner_img" src="https://tgimage.xiangbaoche.cn/d_site_mobile04.png" alt="">
      </div>
      <div class="banner_item">
        <img class="banner_img" src="https://tgimage.xiangbaoche.cn/d_site_mobile05.png" alt="">
      </div>
      <div class="banner_item">
        <img class="banner_img" src="https://tgimage.xiangbaoche.cn/d_site_pc01.png" alt="">
      </div>

    </div>
    <div class="footer" style="background:#f4f4f4;">
      <div class="f_phone_address">
        <div><span class="footer_span_title">客服热线：</span><span class="footer_phone">400-001-6796</span></div>
        <div><span class="footer_span_title">地址：</span><span class="footer_address">厦门市集美区软件园三期B19栋22楼</span></div>
      </div>
      <div class="footer-line"></div>

      <PageFooter />
      <div class="copy_right">
        COPYRIGHT 厦门创狐网络科技有限公司 2018 All RIGHTS RESERVED<a href="https://beian.miit.gov.cn"
          style="text-decoration: none;padding-left:8px;"> 闽ICP备16028322号-8</a>
      </div>
    </div>

    <!-- 底部悬浮 -->
    <div class="fix_bottom_bg" v-if="fix_bottom">
      <img src="https://tgimage.xiangbaoche.cn/tgbaochecom.png" alt="">
      <img src="../assets/images/fix_close.png" alt="" class="fix_close" @click="closeFixBtoom">
      <div style="position: absolute;right: 10px;bottom: 10px;">
        <version :showDev="false"/>
      </div>
    </div>


    <div class="about louceng" v-if="isAbout">
      <div class="clost_btn" @click="isAbout=false">
        <img src="../assets/images/about/close_btn.png" alt="关闭按钮">
      </div>
      <div class="about-head">关于享包车</div>
      <!-- <div class="about-head"><img src="../../../static/images/about/icon_aboutus.png" alt=""></div> -->
      <div class="about-content container"><img src="../assets/images/about/aboutus01.jpg" alt="">
        <p>“享包车出行”为厦门创狐网络科技有限公司专营品牌，自成立之日起，为了解决传统租车价格不透明，服务差劲、趁机加价、手续繁琐这些行
          业乱像和市场痛点，我们以“畅享包车，快乐出行”为己任，全身心打造一款省心、易用又高效的包车产品，让“租赁客运+互联网”拥抱我们的生活，改变我们的出行方式。</p>
        <p>
          “一键约车，多维筛选 ”是我们产品设计的基本理念，用户任意发布一条行程都会有几家车队提供不同的项目组合报价；车队资质、车辆情况、司机水平、等级情况等属性清晰展示，并可在同一维度上进行比较，客户可根据需求做出最适合自
          己的选择；另外，我们还领先于行业提出违约三倍赔偿、用户下单跟单员一对一全程服务、接到投诉24小时内给结果，这些举措无不说明我们执著追求极佳的用户体验性以及良好的行业自律性，
          也说明我们用心打造一款优秀产品的良苦用心，其实这也是我们坚持的初心。
        </p>
      </div>
      <p class="container">当前享包出行已更新迭代至3.0版本，新版本新增了“自驾租车”版块并且在以往“配驾包车”版块中加入“线路包车”及“任性包车”两大项目；
        新版产品还从用户行为体验、产品逻辑、界面交互设计等方面作了全新优化升级；此外，产品从车队资质、客户评价、车队信用值等级、
        司机准入标准等方面对服务车队进行了全方位约束和管控，避免了车队违约、司机无故迟到、服务态度恶劣、用车途中趁机加价等现象发生。
        改变传统出行方式、解决行业乱象、树立“租赁客运+互联网”行业服务标准，让网约租车从此变得省心舒心又放心，这正是我们孜孜不倦的追求！
      </p>
      <div class="about-us"><img src="../assets/images/about/aboutus02.jpg" alt=""></div>
    </div>


    <div class="about_mask" @click="isAbout = false" v-show="isAbout"></div>

    <div class="float_red_packet" v-show="fix_right" @click="getRedPacket">
      <img src="../assets/images/tg_icon_02.png" style="width: 100%; height: 100%">
    </div>

    <div class="video_wrapper" v-show="showVideo">
      <div class="video_wrapper_inner">
        <video controls src="https://tgimage.xiangbaoche.cn/c_site_video.mp4" ref="video"></video>
        <div class="video_close">
          <img src="../assets/images/tg_close.png" alt="" @click="closeVideo">
        </div>
        <div class="video_mask" v-show="isEnd">
          <div class="replay_btn" @click="playVideo"><img src="../assets/images/replay.png" alt="">
            <div>重新播放</div>
          </div>
        </div>
      </div>
      <div class="video_mask_bg" @click="closeVideo"></div>
    </div>
  </div>
</template>

<script>
  import PageFooter from '@/components/pageFooter/index.vue'
  import version from '@/components/version/index.vue'
  import countTo from 'vue-count-to';
  export default {
    data() {
      return {
        isEnd: false,
        fix_right: false,
        fix_bottom: false,
        show_down: false,
        isAbout: false,
        showVideo: false,
        endVal: {
          value1: 0,
          value2: 0,
          value3: 0
        }
      }
    },
    components:{
      countTo,
      PageFooter,
      version
    },
    mounted() {
      this.$refs.video.onended = () => {
        console.log("播放结束了")
        this.isEnd = true
      }
      this.$refs.video.onplay = () => {
        this.isEnd = false
      }

      window.addEventListener('scroll', this.handleScroll)

      this.io = new IntersectionObserver((entries) => {
        console.log(entries)
        if(entries[0].intersectionRatio > 0) {  // 可见
          this.endVal = {
            value1: 500,
            value2: 2395,
            value3: 360
          }
        } else {  // 不可见
          this.endVal = {
            value1: 0,
            value2: 0,
            value3: 0
          }
        }
      }, {});

      // 开始观察
      this.io.observe(document.getElementById('count_to_number'));
    },
    methods: {
      playVideo() {
        this.showVideo = true
        this.$refs.video.play()
      },
      closeVideo() {
        this.showVideo = false
        this.$refs.video.pause()
      },
      handleScroll(e) {
        let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
        //变量windowHeight是可视区的高度
        var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
        //变量scrollHeight是滚动条的总高度
        var scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;

        var isBottom = (scrollTop + windowHeight >= scrollHeight - 50)

        if (scrollTop < 900) {
          this.fix_bottom = false
          this.fix_right = false
        }

        if (scrollTop > 900 && !isBottom) {
          if (this.fix_right) {

          } else {
            this.fix_bottom = true
            this.fix_right = false
          }
        }

        if (isBottom) {
          console.log('到底了')
          this.fix_bottom = false;
          // this.fix_right = true
        }
      },
      getRedPacket() {
        this.fix_right = false
        this.fix_bottom = true
      },
      closeFixBtoom() {
        this.fix_bottom = false
        this.fix_right = true
      }
    }
  }
</script>

<style scoped>
  .nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 68px;
    background-color: #1C1C1C;
    z-index: 10;
  }

  .nav_inner {
    width: 1200px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
  }
  .nav_left{
    display: flex;
    align-items: center;
  }

  .nav_left .nav_logo {
    width: 177px;
    height: 45px;
  }

  .nav_left .logo_box {
    width: 40px;
    height: 40px;
  }

  .logo_box img {
    width: 100%;
    height: 100%;
  }

  .logo_txt .txt_01 {
    color: #fff;
    font-size: 16px;
    font-weight: 700;
  }

  .logo_txt .txt_02 {
    color: #9a9c9e;
    font-size: 12px;
  }

  .nav_right {
    display: flex;
    align-items: center;
  }

  .nav_red_pack img {
    width: 85px;
    height: 40px;
  }

  .red_pack_text {
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    margin-right: 14px;
    margin-top: -4px;
  }

  .red_pack_text_number {
    color: #FFDA44;
    font-size: 24px;
  }

  .red_pack_text_unit {
    color: #FFDA44;
    font-size: 16px;
  }

  .nav_right_down_btn {
    position: relative;
    color: #1C1C1C;
    width: 124px;
    height: 40px;
    background: #FFDA44;
    border-radius: 20px;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
    line-height: 40px;
  }

  .down_load_code {
    width: 220px;
    height: 283px;
    position: absolute;
    bottom: -300px;
    left: 50%;
    margin-left: -110px;
  }

  .down_load_code img {
    width: 100%;
    height: 100%;
  }

  .nav_right_down_btn .down_img {
    width: 135px;
    height: 55px;
    cursor: pointer;
  }

  .main {
    min-width: 1200px;
  }

  .banner_item {
    line-height: 0;
    width: 100%;
    position: relative;
  }

  .banner_item .banner_img {
    width: 100%;
    height: auto;
    min-height: 300px;
  }

  .video_wrapper {
    width: 100%;
    position: fixed;
    height: 100%;
    z-index: 10;
    top: 0;
    left: 0;
    background:rgba(0,0,0,0.5);
  }

  .video_wrapper video {
    height: 562px;
  }
  .video_wrapper_inner{
    position: relative;
    height: 562px;
    width: fit-content;
    margin: auto;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
  }
  .video_mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    background-image: url(../assets/images/video_mask.png);
    background-size: 100% 100%;
  }
  .video_mask_bg{
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  .video_close{
    position: absolute;
    right: 0;
    top: 0;
    z-index: 9000;
    width: 100%;
    height: 75px;
    background: linear-gradient(180deg,rgba(0,0,0,.5),transparent);
  }
  .video_close img{
    width: 22px;
    height: 22px;
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
  }
  .replay_btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.8);
    width: 202px;
    height: 52px;
    align-items: center;
    border-radius: 22px;
    display: flex;
    justify-content: center;
    color: #FFFFFF;
    font-size: 21px;
    font-weight: 400;
    cursor: pointer;

  }

  .replay_btn img {
    width: 26px;
    height: 27px;
    margin-right: 8px;
  }

  .footer {
    min-width: 1200px;
  }

  .f_phone_address {
    width: 1200px;
    padding: 25px 178px;
    margin: auto;
    display: flex;
    justify-content: space-between;
  }

  .footer-line {
    width: 1200px;
    margin: auto;
    height: 1px;
    background: #E3E3E3;
  }

  .copy_right {
    text-align: center;
    padding: 25px 0;
    color: #999999;
    font-size: 14px;
  }

  .copy_right a {
    color: #999;
  }

  .footer_span_title {
    color: #333333;
    font-size: 16px;
  }

  .footer_phone {
    color: #4688DA;
    font-size: 24px;
    font-weight: bold;
  }

  .footer_address {
    color: #666666;
    font-size: 16px;
  }

  .fix_bottom_bg {
    position: fixed;
    bottom: 30px;
    width: 918px;
    height: 180px;
    left: 50%;
    margin-left: -459px;
  }

  .fix_bottom_bg img {
    width: 100%;
    height: 100%;
  }

  .fix_bottom_bg .fix_close {
    position: absolute;
    top: 88px;
    right: 31px;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  /* 公司简介 */

  .company_introduction {
    border-right: 1px solid #e3e3e3;
    height: 16px;
    line-height: 16px;
    padding-right: 20px;
    font-size: 16px;
    color: #fff;
    cursor: pointer;
  }


  /* 关于 */

  .about {
    background: #fff;
    padding-bottom: 50px;
    position: fixed;
    width: 1096px;
    height: 800px;
    top: 50%;
    left: 50%;
    z-index: 500;
    transform: translate(-50%, -50%);
    padding: 20px 120px;
    overflow: scroll;
    box-sizing: border-box;
    overflow-x: hidden;
    border-radius: 10px;
  }

  .about-head {
    width: 175px;
    height: 27px;
    padding: 40px 0 53px 0;
    margin: 0 auto;
    color: #333333;
    font-size: 24px;
    font-weight: bold;
  }

  .clost_btn {
    position: absolute;
    right: 30px;
    cursor: pointer;

  }

  .clost_btn img {
    width: 22px;
  }

  .about-head img {
    width: 100%;
    height: 27px;
  }

  .about .container {
    width: 100%;
    margin: 0 auto;
  }

  .about-content img {
    width: 350px;
    height: 223px;
    float: right;
    margin: 0 0 6px 50px;
  }

  .about p {
    font-size: 14px;
    font-family: MicrosoftYaHei;
    font-weight: 400;
    color: #666666;
    line-height: 30px;
    margin-bottom: 20px;
  }

  .about-us {
    width: 830px;
    height: 425px;
    margin: 0 auto;
  }

  .about-us img {
    width: 100%;
    height: 100%;
  }

  .about_mask {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 499;
    background-color: rgba(0, 0, 0, 0.4);
  }


  .float_red_packet {
    width: 106px;
    height: 132px;
    position: fixed;
    top: 50%;
    right: 90px;
    cursor: pointer;
  }
  .count_to_number{
    width: 1200px;
    height: 338px;
    background: #FFFFFF;
    box-shadow: 0px 7px 57px 0px rgba(106, 114, 131, 0.1);
    position: absolute;
    bottom: 12%;
    left: 50%;
    transform: translateX(-50%);
    box-sizing: border-box;
  }
  .count_to_number_title{
    text-align: center;
    color: #1D1C1C;
    font-size: 30px;
    line-height: 42px;
    padding-top: 30px;
  }
  .yellow_line{
    width: 40px;
    height: 9px;
    background: #FFE240;
    border-radius: 5px;
    margin: auto;
    margin-top: 10px;
  }
  .num_wrapper{
    margin-top: 50px;
    width: 100%;
    padding: 0 117px;
    display: flex;
    justify-content: space-between;
    line-height: 73px;
  }

  .num_box{
    text-align: center;
  }
  .num_box_number{
    font-family: DIN Medium;
    font-weight: 400;
    font-size: 80px;
    color: #1D1C1C;
  }
  .num_box_unit{
    font-size: 30px;
    font-weight: bold;
    color: #1D1C1C;
    margin-left: -22px;
    line-height: 0;
  }
  .num_box_text{
    color: #1D1C1C;
    font-size: 24px;
  }
</style>
