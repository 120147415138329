import axios from 'axios'; // 引入axios
import qs from 'qs'



const http = axios.create({
  timeout: 1000 * 20, // 请求超时时间
  baseURL: 'https://copyapixbc.xiangbaoche.cn/api.php/main',
  // withCredentials: true,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  },
  transformRequest: [function(data){ // 对 data 进行任意转换处理 针对后端post请求使用RequestParams获取参数处理如果使用RequestBody则不需要
      return qs.stringify(data);
  }]
})

// 添加请求拦截器
http.interceptors.request.use(
  config => {
    return config
  },
  error => {
    return Promise.reject(error)
  }
)
// 添加响应拦截器
http.interceptors.response.use(
  response => {
    return Promise.resolve(response)
  },
  error => {
    const msg = error.Message !== undefined ? error.Message : '出错啦, 请稍后重试!'
    return Promise.reject(error)
  }
)

const request = (url, data = {}, type = "POST") => {
  if (type === 'GET') {
    return http.get(url, {
      params: data
    })
  } else {
    return http.post(url, data)
  }
}

export default request
